<!--  -->
<template>
  <el-dialog 
    title="选择已有公司"
    class="dialog_content" 
    :visible="showDialog"
    @open="handleAction"
    @close="$emit('update:showDialog', false)">
    <el-table 
      border
      stripe
      class="table_content"
      v-loading="listLoading"
      :data="companyList.list"
      :header-cell-style="{backgroundColor: 'rgb(243, 243, 243)', color: 'rgb(102, 102, 102)', height: '36px', padding: 0}"
      :row-style="{height: '36px'}"
      :cell-style="{padding: 0}">
      <el-table-column align="center" type="index" label="序号" width="60"></el-table-column>
      <el-table-column property="companyName" label="公司名称"></el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="handleSelect(row)">选择</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="pagination_content"
        background
        layout="prev, pager, next"
        :total="companyList.total"
        :page-size="10"
        :current-page="page.current"
        @current-change="handleChangePage">
      </el-pagination>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { getCompanyInfo } from '@/api'
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      page: {
        size: 10,
        current: 0
      },
    };
  },

  computed: {
    ...mapState(['listLoading']),
    ...mapGetters(['companyList'])
  },
  created () {
    this.getCompanyList(this.page)
  },
  methods: {
    ...mapActions(['getCompanyList']),
    handleChangePage (param) {
      this.page = {
        ...this.page,
        current: param
      }
      this.handleAction()
    },
    submit () {
      this.page = {
        current: 0,
        size: 10
      }
      this.handleAction()
    },
    handleAction () {
      this.getCompanyList(this.page)
    },
    async handleSelect (data) {
      const res = await getCompanyInfo(data.id)
      this.$emit('select', res.data.data)
      this.$emit('update:showDialog', false)
    }
  }
}

</script>
<style lang='scss' scoped>
.dialog_content {
  .table_content {
    margin-bottom: 20px;
  }
  .pagination_content {
    text-align: right;
  }
}
</style>