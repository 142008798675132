<template>
  <div style="background:#edf3fe;padding-top: 80px;height:100%;">
    <h2 class="title1">填写公司信息</h2>
    <el-card class="nav">
      <!-- 步骤条 -->
      <Steps :active-index="activeIndex" :is-show="merId && merId !== ''"></Steps>
    </el-card>
    <el-card class="main_card">
      <p class="title">公司基本信息</p>
      <el-divider class="divider"></el-divider>
      <!-- 公司信息 银行信息 -->
      <el-form
        ref="merchantInfoFrom"
        class="merchantInfoFrom"
        label-width="140px"
        :model="companyData"
        :rules="rules"
      >
        <el-row>
          <el-col :span="14">
            <el-form-item label="公司名称:" prop="name">
                <el-input
                  style="width: 100%;"
                  class="input_before"
                  v-model.trim="companyData.name"
                  placeholder="请输入公司名称"
                  clearable
                  :disabled="!canUpdate"
                >
                </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" :offset="1">
            <el-button type="primary" @click="showCompanyList=true">填写已有公司</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="统一信用代码:" prop="creditCode">
              <el-input
                style="width: 100%;"
                class="input_before"
                v-model.trim="companyData.creditCode"
                placeholder="请输入统一信用代码"
                clearable
                :disabled="!canUpdate"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="手机号码:" prop="coLinkmanPhone">
              <el-input
                style="width: 100%;"
                class="input_before"
                v-model.trim="companyData.coLinkmanPhone"
                placeholder="请输入手机号码"
                clearable
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        
        
        <el-form-item label="营业执照上传:" prop="licResIdList">
          <Upload
            v-model="companyData.licResIdList"
            :src="licResUrl"
            :a-disabled="!canUpdate"
            @onSuccess="handleUploadSuccess('licResIdList')"
            @onRemove="handleUploadRemove('licResIdList')"

            >
          </Upload>
        </el-form-item>
        <el-form-item label="有效期限类型:" prop="licDeadlineType">
          <el-radio-group
            class="input_before"
            v-model="companyData.licDeadlineType"
            size="medium"
            :disabled="!canUpdate">
            <el-radio
              v-for="item in termOptions"
              :key="item.dictValue"
              :label="item.dictValue">
              {{ item.dictLabel }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="营业执照有效日期:"
          prop="licDeadlineDate"
          v-if="companyData.licDeadlineType == 2"
        >
          <el-date-picker
            class="input_before"
            v-model="companyData.licDeadlineDate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="请选择营业执照有效日期"
            clearable
            :disabled="!canUpdate"
          ></el-date-picker>
        </el-form-item>
        <el-row>
          <el-col :span="14">
            <el-form-item label="公司性质:" prop="nature">
              <el-select
                style="width: 100%;"
                class="input_before"
                v-model="companyData.nature"
                placeholder="请选择公司性质"
                clearable
                :disabled="!canUpdate"
                @change="selectType"
              >
                <el-option
                  v-for="(item, index) in companyNatureList"
                  :key="index"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="公司注册地址:" prop="coRegAddress" style="width: 100%;">
              <RegionList
                style="width: 100%;"
                holder="请选择公司地址"
                v-model="companyData.coRegAddress"
                @handleChange="handleRegAddress"
                :disabled="!canUpdate"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="1">
            <el-form-item style="width:90%;" label-width="0" prop="coRegAddrDetail">
              <el-input
                  class="input_after"
                  style="width: 100%;"
                  v-model.trim="companyData.coRegAddrDetail"
                  placeholder="请输入详细地址"
                  clearable
                  :disabled="!canUpdate2"
                ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="开票信息地址:" prop="ticketAddress" style="width: 100%;">
              <RegionList
                style="width: 100%;"
                holder="请选择开票信息地址"
                v-model="companyData.ticketAddress"
                @handleChange="handleTicketAddress"
                :disabled="!canUpdate2"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="1">
            <el-form-item style="width:90%;" label-width="0" prop="ticketAddrDetail">
              <el-input
                style="width: 100%;"
                class="input_after"
                v-model.trim="companyData.ticketAddrDetail"
                placeholder="请输入详细地址"
                clearable
                :disabled="!canUpdate2"
                ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="联系邮箱:" prop="coLinkmanMail">
              <el-input
                style="width: 100%;"
                class="input_before"
                v-model.trim="companyData.coLinkmanMail"
                placeholder="请输入联系邮箱"
                clearable
                :disabled="!canUpdate"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="邮政编码:" prop="postCode">
              <el-input
                style="width: 100%;"
                class="input_before"
                v-model.trim="companyData.postCode"
                placeholder="请输入邮政编码"
                clearable
                :disabled="!canUpdate"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="纳税人标识:" prop="taxPayerFlag">
              <el-select
                style="width: 100%;"
                class="input_before"
                v-model="companyData.taxPayerFlag"
                placeholder="请选择纳税人标识"
                clearable
                @change="selectFlag"
                :disabled="!canUpdate">
                <el-option
                  v-for="(item, index) in taxpayerFlag"
                  :key="index"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="法人姓名:" prop="legalName">
              <el-input
                style="width: 100%;"
                class="input_before"
                v-model.trim="companyData.legalName"
                placeholder="请输入法人姓名"
                clearable
                :disabled="!canUpdate"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="身份证号:" prop="legalIdCode">
              <el-input
                style="width: 100%;"
                class="input_before"
                v-model.trim="companyData.legalIdCode"
                placeholder="请输入身份证号"
                clearable
                :disabled="!canUpdate"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="身份证正面上传:" prop="cardJustList">
          <Upload
            v-model="companyData.cardJustList"
            :src="cardJustUrl"
            :a-disabled="!canUpdate2"
            @onRemove="handleUploadRemove('cardJustList')"
            @onSuccess="handleUploadSuccess('cardJustList')">
          </Upload>
        </el-form-item>
        <el-form-item label="身份证反面上传:" prop="cardBackList">
          <Upload
            v-model="companyData.cardBackList"
            :src="cardBackUrl"
            :a-disabled="!canUpdate2"
            @onRemove="handleUploadRemove('cardBackList')"
            @onSuccess="handleUploadSuccess('cardBackList')">
          </Upload>
        </el-form-item>
        <el-row>
          <el-col :span="14">
            <el-form-item label="法人手机号:" prop="legalPhone">
              <el-input
                style="width: 100%;"
                class="input_before"
                v-model.trim="companyData.legalPhone"
                placeholder="请输入法人手机号"
                clearable
                :disabled="!canUpdate"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        
      </el-form>
    </el-card>
    <el-card class="main_card">
      <p class="title">银行基本信息</p>
      <el-divider class="divider"></el-divider>
      <el-form
        ref="merchantInfoFrom2"
        class="merchantInfoFrom"
        label-width="140px"
        :model="companyData"
        :rules="rules"
      >
        <el-row>
          <el-col :span="14">
            <el-form-item label="支行名称:" prop="bankDesc">
              <el-input
                style="width: 100%;"
                ref="bankDesc"
                class="input_before"
                v-model="companyData.bankDesc"
                placeholder="请输入支行名称"
                clearable
                disabled
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3" :offset="1">
            <el-button type="primary" @click="handleShow">银行选择</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="银行账号:" prop="bankCode">
            <el-input
              style="width: 100%;"
              class="input_before"
              v-model.trim="companyData.bankCode"
              placeholder="请输入银行账号"
              clearable
              :disabled="!canUpdate"
            >
            </el-input>
          </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="开户行地址:" prop="bankAddress" style="width: 100%;">
              <RegionList
                style="width: 100%;"
                holder="请选择开户行地址"
                v-model="companyData.bankAddress"
                @handleChange="handleBankAddress"
                :disabled="!canUpdate"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="1">
            <el-form-item style="width:90%;" label-width="0" prop="bankAddrDetail">
              <el-input
                style="width: 100%;"
                class="input_after"
                v-model.trim="companyData.bankAddrDetail"
                placeholder="请输入详细地址"
                clearable
                :disabled="!canUpdate2"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14">
            <el-form-item label="银行账户持有人:" prop="bankCountMaster">
              <el-input
                style="width: 100%;"
                class="input_before"
                v-model.trim="companyData.bankCountMaster"
                placeholder="请输入银行账户持有人"
                clearable
                :disabled="!canUpdate"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        
        <el-form-item label="对公对私:" prop="selfOrAll">
          <el-radio-group 
            class="input_before"
            v-model="companyData.selfOrAll"
            size="medium"
            :disabled="!canUpdate2">
            <el-radio
              v-for="item in privateOptions"
              :key="item.dictValue"
              :label="item.dictValue">
              {{ item.dictLabel }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="btn-group">
      <el-button v-if="showPrev" class="btn" size="small" @click="handleBack">上一步</el-button>
      <el-button class="btn" size="small" @click="handleSave">临时保存</el-button>
      <el-button class="btn" size="small" type="primary" @click="handleNext">下一步</el-button>
    </div>
    <CompanyList :show-dialog.sync="showCompanyList" @select="handleCompanySelect"></CompanyList>
    <BankList :show-dialog.sync="showBankList" @select="handleBankSelect"></BankList>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { identityCardsValidator, validatenull } from '@/utils/validate'
import { getUploadFile, getComStatus } from '@/api'
import RegionList from '@/components/RegionList'
import BankList from '@/components/BankList'
import CompanyList from '@/components/CompanyList'
import Upload from '@/components/Upload'
import Steps from '@/components/Steps'

export default {
  components: {
    RegionList,
    BankList,
    CompanyList,
    Upload,
    Steps
  },
  props: [],
  data() {
    return {
      merId: '',
      merchantStatus: '',
      showCompanyList: false,
      showBankList: false,
      bankSelected: false,
      licResUrl: '',
      cardJustUrl: '',
      cardBackUrl: '',
      // 步骤条默认选中
      activeIndex: 2,
      companyList: [
        { index: 1, name: '国美公司'},
        { index: 2, name: '国美家公司'},
        { index: 3, name: '国美科技公司'}
      ],
      companySelect: {},
      page: {
        total: 50,
        size: 10,
        current: 0
      },
      // 公司 银行信息
      companyData: {
        name: undefined,
        creditCode: undefined,
        coLinkmanPhone: null,
        licDeadlineType: null,
        nature: null,
        coRegAddress: null,
        coRegAddrDetail: '',
        ticketAddress: '',
        ticketAddrDetail: '',
        coLinkmanMail: undefined,
        postCode: undefined,
        taxPayerFlag: undefined,
        legalName: undefined,
        legalIdCode: undefined,
        legalPhone: undefined,
        bankDesc: undefined,
        bankCode: undefined,
        bankCountMaster: undefined,
        bankAddress: [""],
        bankAddrDetail: undefined,
        selfOrAll: undefined,
      },
      // 公司银行信息校验规则
      rules: {
        name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { max: 50, message: '最多输入50位字符', trigger: 'change' }
        ],
        creditCode: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' },
          { max: 18, message: '最多输入18位字符', trigger: 'change' }
        ],
        nature: [
          { required: true, message: '请选择公司性质', trigger: 'change' }
        ],
        coLinkmanPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/,
            message: '请输入正确的电话号码',
            trigger: ['change', 'blur']
          }
        ],
        coRegAddress: [
          {
            type: 'array',
            required: true,
            message: '请选择公司注册地址',
            trigger: 'change'
          }
        ],
        coRegAddrDetail: [
          {
            required: true,
            message: '请输入公司注册详细地址',
            trigger: 'blur'
          },
          { max: 100, message: '最多输入100位字符', trigger: 'change' }
        ],
        ticketAddress: [
          {
            type: 'array',
            required: true,
            message: '请选择开票信息地址',
            trigger: 'change'
          }
        ],
        ticketAddrDetail: [
          {
            required: true,
            message: '请输入开票信息详细地址',
            trigger: 'blur'
          },
          { max: 100, message: '最多输入100位字符', trigger: 'change' }
        ],
        coLinkmanMail: [
          { required: true, message: '请输入联系邮箱', trigger: 'blur' },
          {
            pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: '请输入正确的邮箱格式',
            trigger: ['change', 'blur']
          },
          { max: 50, message: '最多输入50位字符', trigger: 'change' }
        ],
        postCode: [
          { required: true, message: '请输入邮政编码', trigger: 'blur' },
          {
            pattern: /^[0-9]\d{5}$/,
            message: '请输入正确的邮政编码',
            trigger: ['change', 'blur']
          }
        ],
        taxPayerFlag: [
          { required: true, message: '请选择纳税人标识', trigger: 'change' }
        ],
        legalName: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' },
          { max: 10, message: '最多输入10位字符', trigger: 'change' }
        ],
        legalPhone: [
          { required: true, message: '请输入法人手机号', trigger: 'blur' },
          { max: 11, message: '最多输入11个字符', trigger: 'change' },
          {
            pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/,
            message: '请输入有效的手机号码',
            trigger: ['change', 'blur']
          }
        ],
        bankDesc: [
          { required: true, message: '请输入支行名称', trigger: 'change' },
          { max: 40, message: '最多输入40位字符', trigger: 'change' }
        ],
        bankCode: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
          {
            pattern: /^([1-9]{1})(\d{0,19})$/,
            message: '请输入正确的银行账号',
            trigger: ['change', 'blur']
          }
        ],
        bankCountMaster: [
          { required: true, message: '请输入银行账号持有人', trigger: 'blur' },
          { max: 50, message: '最多输入50位字符', trigger: 'change' }
        ],
        bankAddress: [
          {
            type: 'array',
            required: true,
            message: '请选择开户行地址',
            trigger: 'change'
          }
        ],
        bankAddrDetail: [
          { required: true, message: '请输入开户行详细地址', trigger: 'blur' },
          { max: 100, message: '最多输入100位字符', trigger: 'change' }
        ],
        selfOrAll: [
          { required: true, message: '请选择对公对私', trigger: 'change' }
        ],
        licDeadlineType: [
          { required: true, message: '请选择有效期限类型', trigger: 'change' }
        ],
        licDeadlineDate: [
          { required: true, message: '请选择有效日期', trigger: 'change' }
        ],
        licResIdList: [
          {
            type: 'array',
            required: true,
            message: '请上传营业执照',
            trigger: 'change'
          }
        ],
        legalIdCode: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          // {
          //   pattern: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
          //   message: '请输入正确的身份证号',
          //   trigger: ['change', 'blur']
          // },
          { validator: identityCardsValidator, trigger: 'blur' },
          { max: 18, message: '最多输入18位字符', trigger: 'change' }
        ],
        cardJustList: [
          {
            type: 'array',
            required: true,
            message: '请上传身份证正面',
            trigger: 'change'
          }
        ],
        cardBackList: [
          {
            type: 'array',
            required: true,
            message: '请上传身份证反面',
            trigger: 'change'
          }
        ]
      },
      companySiteProps: {
        multiple: false,
        label: "name",
        value: "code",
        children: "childList",
        expandTrigger: 'hover'
      },
      infoSiteProps: {
        multiple: false,
        label: "label",
        value: "value",
        children: "children",
      },
      bankAddressProps: {
        multiple: false,
        label: "label",
        value: "value",
        children: "children",
      },
      
    };
  },
  computed: {
    ...mapGetters(['companyNatureList', 'taxpayerFlag', 'bankList', 'privateOptions', 'termOptions']),
    termOptions1 () {
      return this.termOptions.map(item => {
        if (item.dictValue == '有效') {
          item.dictValue === '有限'
        }
      })
    },
    showPrev () {
      return false
      // return (Object.keys(this.companyData).length > 0) && (!this.companyData.merId || this.companyData.merId === '')
    },
    justDisable () {
      console.log(666, this.companyData.coIsCreateFlg, validatenull(this.companySelect.cardJustList), this.companyData.coIsCreateFlg==='0' && !validatenull(this.companySelect.cardJustList))
      return this.companyData.coIsCreateFlg==='0' && !validatenull(this.companySelect.cardJustList)
    },
    backDisable () {
      return this.companyData.coIsCreateFlg==='0' && !validatenull(this.companySelect.cardBackList)
    },
    canUpdate () {
      return this.merchantStatus == '' || this.merchantStatus === '1'
    },
    canUpdate2 () {
      return this.merchantStatus == '' || this.merchantStatus === '1' || this.merchantStatus === '2'
    },
  },
  async created() {
    
    this.companyData = this.getFormData()
    this.companySelect = JSON.parse(localStorage.getItem('company_select')) || {}
    this.merId = this.companyData.merId
    if (this.companyData.licResIdList && this.companyData.licResIdList.length) {
      getUploadFile(this.companyData.licResIdList[0]).then(response => {
        this.licResUrl = response.data.data.res.url
      })
    }
    if (this.companyData.cardJustList && this.companyData.cardJustList.length) {
      getUploadFile(this.companyData.cardJustList[0]).then(response => {
        this.cardJustUrl = response.data.data.res.url
      })
    }
    if (this.companyData.cardBackList && this.companyData.cardBackList.length) {
      getUploadFile(this.companyData.cardBackList[0]).then(response => {
        this.cardBackUrl = response.data.data.res.url
      })
    }
    // 确定是否可以修改
    this.getAllDictionaryList()
    this.getRegionList()

    // 获取公司状态信息
    if (this.companyData.name && this.companyData.name !== '') {
      const res = await getComStatus(this.companyData.name)
      this.merchantStatus = res.data.data
    }
  },
  methods: {
    validatenull,
    ...mapActions(['getAllDictionaryList', 'getRegionList']),
    handleShow () {
      if (this.companyData.coIsCreateFlg === '0') return
      this.showBankList = true
    },
    // 商户基本信息提交
    submit() {
      this.$refs["mechantForm"].validate((valid) => {
        if (!valid) return;
      });
    },
    resetForm() {
      // this.$refs["merchantInfoFrom"].resetFields();
    },
    goBack () {
      this.$router.go(-1)
    },
    
    handleBack() {
      localStorage.setItem('merchant_info', JSON.stringify(this.companyData))
      this.$router.push('merchantInfo')
    },
    handleSave() {
      localStorage.setItem('merchant_info', JSON.stringify(this.companyData))
      this.$message({
        showClose: true,
        message: '保存成功!',
        type: 'success'
      });
    },
    handleNext () {
      this.$refs['merchantInfoFrom'].validate(valid => {
        this.$refs['merchantInfoFrom2'].validate(valid2 => {
          if (!valid || !valid2) return
          this.saveFormData()
          this.$router.push('brandInfo')
        })
      })
    },
    saveFormData () {
      if (!this.companyData.coIsCreateFlg) {
        this.companyData.coIsCreateFlg = '1'
      }

      let options = {
        bankAddrProvince: this.companyData.bankAddress[0],
        bankAddrCity: this.companyData.bankAddress[1],
        bankAddrCounty: this.companyData.bankAddress[2],
        coRegAddrProvince: this.companyData.coRegAddress[0],
        coRegAddrCity: this.companyData.coRegAddress[1],
        coRegAddrCounty: this.companyData.coRegAddress[2],
        ticketAddrProvince: this.companyData.ticketAddress[0],
        ticketAddrCity: this.companyData.ticketAddress[1],
        ticketAddrCountry: this.companyData.ticketAddress[2],
        licResId: this.companyData.licResIdList[0], // 营业执照
        licResIdList: this.companyData.licResIdList, // 营业执照
        idCardJustPath: this.companyData.cardJustList[0], // 身份证正面
        idCardBackPath: this.companyData.cardBackList[0], // 身份证反面
        coRegAddr: this.companyData.coRegAddressLabel ? this.companyData.coRegAddressLabel.join('') : this.companyData.coRegAddr, // 商户住所
        ticketAddr: this.companyData.ticketAddressLabel ? this.companyData.ticketAddressLabel.join('') : this.companyData.ticketAddr, // 开票信息地址
        bankAddr: this.companyData.bankAddressLabel ? this.companyData.bankAddressLabel.join('') : this.companyData.bankAddr, // 开户行地址
        coRegAddrCityC: this.companyData.coRegAddressLabel ? this.companyData.coRegAddressLabel[1] : this.companyData.coRegAddrCityC, // 营业执照注册城市
        coRegAddrProvinceC: this.companyData.coRegAddressLabel ? this.companyData.coRegAddressLabel[0] : this.companyData.coRegAddrProvinceC, // 营业执照地址省
        bankAddrCityC: this.companyData.bankAddressLabel ? this.companyData.bankAddressLabel[1] : this.companyData.bankAddrCityC // 银行所在城市中文
      }
      this.companyData = {
        ...this.companyData,
        ...options
      }
      localStorage.setItem('merchant_info', JSON.stringify(this.companyData))
    },
    getFormData () {
      const data = JSON.parse(localStorage.getItem('merchant_info1')) || {}
      const saveData = JSON.parse(localStorage.getItem('merchant_info')) || {}
      return {
        ...data,
        ...saveData
      }
    },
    handleBankSelect (item) {
      if (item) {
        this.$set(this.companyData, 'bankDesc', item.bankName)
        this.$set(this.companyData, 'bankDescCode', item.bankCode)
        this.$refs.merchantInfoFrom2.validateField('bankDesc')
        this.bankSelected = true
      }
    },
    async handleCompanySelect (data) {
      this.bankSelected = true
      this.licResUrl = ''
      this.cardJustUrl = ''
      this.cardBackUrl = ''
      let options = {}
      options.coIsCreateFlg = '0'
      options.coRegAddrDetailIsNull = validatenull(data.coRegAddrDetail)
      options.ticketAddrDetailIsNull = validatenull(data.ticketAddrDetail)
      options.bankAddrDetailIsNull = validatenull(data.bankAddrDetail)
      options.name = data.name // 公司名称
      options.creditCode = data.creditCode // 统一社会信用代码
      options.nature = data.nature // 公司性质
      if (!data.coRegAddrProvince && !data.coRegAddrCity && !data.coRegAddrCounty) {
        options.coRegAddress = []
      } else {
        options.coRegAddress = [
          data.coRegAddrProvince,
          data.coRegAddrCity,
          data.coRegAddrCounty
        ] // 注册地址
      }
      options.coRegAddrDetail = data.coRegAddrDetail // 详细注册地址
      if (!data.ticketAddrProvince && !data.ticketAddrCity && !data.ticketAddrCountry) {
        options.ticketAddress = []
      } else {
        options.ticketAddress = [
          data.ticketAddrProvince,
          data.ticketAddrCity,
          data.ticketAddrCountry
        ] // 开票地址
      }
      
      options.ticketAddrDetail = data.ticketAddrDetail // 开票详细地址
      options.coLinkmanPhone = data.coLinkmanPhone // 公司联系电话
      options.coLinkmanMail = data.coLinkmanMail // 联系邮箱
      options.postCode = data.postCode // 邮政编码
      options.legalName = data.legalName // 法人姓名
      options.legalPhone = data.legalPhone // 法人手机号
      if (data.licResId) {
        options.licResIdList = [data.licResId]
      } else {
        options.licResId = data.licResId = data.licResIdList[0]
        options.licResIdList = [options.licResId]
      }
      options.cardJustList = data.idCardJustPath ? [data.idCardJustPath] : [] // 身份证正面
      options.cardBackList = data.idCardBackPath ? [data.idCardBackPath] : [] // 身份证反面
      options.legalIdCode = data.legalIdCode // 身份证号
      options.licDeadlineType = data.licDeadlineType // 有效期限类型
      options.licDeadlineDate = data.licDeadlineDate // 有效期
      options.taxPayerFlag = data.taxPayerFlag // 纳税人标识
      options.bankDescCode = data.bankDescCode // 支行Code
      options.bankDesc = data.bankDesc // 支行名称
      options.bankCode = data.bankCode // 银行账号
      options.bankCountMaster = data.bankCountMaster // 银行账号持有人
      if (!data.bankAddrProvince && !data.bankAddrCity && !data.bankAddrCounty) {
        options.bankAddress = []
      } else {
        options.bankAddress = [
          data.bankAddrProvince,
          data.bankAddrCity,
          data.bankAddrCounty
        ] // 开户行地址
      }
      options.bankAddrDetail = data.bankAddrDetail // 详细地址
      options.selfOrAll = data.selfOrAll // 对公对私
      if (data.licResId) {
        getUploadFile(data.licResId).then(response => {
          this.licResUrl = response.data.data.res.url
        })
      }
      if (data.idCardJustPath) {
        getUploadFile(data.idCardJustPath).then(response => {
          this.cardJustUrl = response.data.data.res.url
        })
      }
      if (data.idCardBackPath) {
        getUploadFile(data.idCardBackPath).then(response => {
          this.cardBackUrl = response.data.data.res.url
        })
      }
      // 完成选择公司后校验字段
      this.companySelect = options
      localStorage.setItem('company_select', JSON.stringify(options))
      this.companyData = {
        ...this.companyData,
        ...data,
        ...options
      }
     
      this.$nextTick(() => {
        this.$refs.merchantInfoFrom.validate()
        this.$refs.merchantInfoFrom2.validate()
      })
      this.saveFormData()

      // 获取公司状态信息
      const res = await getComStatus(data.name)
      this.merchantStatus = res.data.data
    },
    handleBankAddress (arr) {
      this.$set(this.companyData, 'bankAddressLabel', arr)
    },
    handleTicketAddress (arr) {
      this.$set(this.companyData, 'ticketAddressLabel', arr)
    },
    handleRegAddress (arr) {
      this.$set(this.companyData, 'coRegAddressLabel', arr)
    },
    selectType (val) {
      const obj = this.companyNatureList.find(item => +item.dictValue === +val)
      this.companyData.typeLabel = obj.dictLabel
    },
    selectFlag (val) {
      const obj = this.taxpayerFlag.find(item => +item.dictValue === +val)
      this.companyData.taxpayerFlagLabel = obj.dictLabel
    },
    handleUploadSuccess (name) {
      this.$refs.merchantInfoFrom && this.$refs.merchantInfoFrom.validateField(name);
    },
    handleUploadRemove (name) {
      this[name] = ''
      this.companyData[name] = []
      if (name === 'licResIdList') {
        this.companyData.licResId = null
        this.companyData.licResIdList = []
      }
    }
  },
};
</script>

<style scoped lang="scss">
.title1 {
  width: 80%;
  margin: 20px auto;
  font-size: 20px;
}
.nav {
  width: 80%;
  margin: 20px auto;
}
.main_card {
  width: 80%;
  margin: 20px auto;
  box-sizing: border-box;
  
  .el-form-item {
    margin-bottom: 20px;
  }
  .title {
    font-size: 18px;
    margin: 0;
  }
  .divider {
    margin: 8px 0 24px 0;
  }
  //  商户基本信息
  .merchantInfoFrom {
    width: 60%;
  }

  .header_steps {
    width: 80%;
    margin: 40px auto;
  }

  .space_between {
      display: flex;

    }
    .input_before {
      width: 50%;
      margin-right:2%;
    }
    .input_after {
      width: 48%;
    }
}

\deep\ .inline-input .main_card .input_before {
  width: 95%;
}

.submitWrap {
  margin-top: 4.4%;
  width: 100%;
  text-align: center;
}


.btn-group {
  display: flex;
  justify-content: center;
  margin: 20px;

  .btn {
    width: 124px;
    height: 40px;
    border-radius: 5px;
    margin: 0 20px;
    font-size: 18px;
  }
}

.dialog_content {
  .table_content {
    margin-bottom: 20px;
  }
  .pagination_content {
    text-align: right;
  }
}
</style>
